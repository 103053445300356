//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-988:_84,_4808,_908,_6476,_5216,_3268,_4388,_7992;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-988')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-988', "_84,_4808,_908,_6476,_5216,_3268,_4388,_7992");
        }
      }catch (ex) {
        console.error(ex);
      }